<template>
  <span :class="$style.box"><slot /></span>
</template>

<style lang="scss" module>
/* padding 外から仕込みたいケース結構あるので詳細度下げとく  */
:where(.box) {
  display: inline-flex;
  align-items: center;
  justify-items: center;
  border-radius: 100vh;
  padding: 0 0.6em;
}
</style>
